.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 210px;
  height: 347px;
  margin-bottom: 20px;
}

.product-card-img {
  height: 50%;
}

.product-card-img img {
  max-width: 100%;
  max-height: 100%;
}

.product-card-description {
  padding: 0 18px;
  text-align: center;
}

.product-card-description h1 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.product-card-description p {
  font-size: 14px;
  font-style: italic;
  text-align: left;
  padding-top: 14px;
}

.product-card-action {
  display: flex;
  height: 40px;
  width: 90%;
}

.product-card-action button {
  width: 100%;
  border-radius: 5px;
}

.btn h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
