@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-bottom: 0;
}

.base-card {
  background-color: #EDEDED;
  border-radius: 10px;
}

a, a:hover {
  text-decoration: none;
  color: unset;
}